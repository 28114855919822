import {createBrowserRouter} from 'react-router-dom'
import Home from './page/home';
import Solution from './page/solution'
import About from './page/about'



const router = createBrowserRouter([
    {
        path: '/home',
        element: <Home/>
    },
    {
        path: '/',
        element: <Home/>
    },
    {
        path: '/solution',
        element: <Solution/>
    },
    {
        path: '/about',
        element: <About/>
    }

]);

export default router;