import './index.css';
import HeadView from '../../ components/head'
import FooterView from  '../../ components/footer'
import {Divider} from 'antd';

const APP = () => {

    return (
        <div>
            <HeadView />
            <div className='item_title'>
                 <span>个性化系统，小程序定制</span>
            </div>
            <div className='solution_items'>
                <div className='solution_item_1'>
                    <div><img src={require('../../images/bg05.png')} alt=''/></div>
                    <div className='solution_item_title'>
                        <span>ERP系统个性化定制,提供OA办公系统，流程审批，数据报表，人员管理，权限管理。</span>
                    </div>
                </div>
                <div className='item_title'>
                    <span>小程序代注册，免认证费</span>
                </div>
                <div className='solution_item_2'>
                    <div><img src={require('../../images/bg02.png')} alt=''/></div>
                    <div className='solution_item_title'>
                        <span>根据微信开放平台为简化开发小程序，代申请小程序认证将免收300元认证费用。</span>
                    </div>
                </div>
            </div>
            <FooterView />
        </div>
    )

}


export default APP;