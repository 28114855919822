import {Divider} from 'antd';
import {BulbFilled,PieChartFilled,BugFilled,BuildFilled,LockFilled,SettingFilled,FundFilled,CloudFilled,
  RocketFilled,ThunderboltFilled} from '@ant-design/icons';
import './index.css';
import head_cener_right from '../../images/head_center_right.gif';
import FooterView from '../../ components/footer'
import HeadView from '../../ components/head'


const Home  = ()=>{

    return (
        <div>
          <div className='base_top'>
            <HeadView/>
            <div className="bg_content">
              <div className='text_detail'>
                <p>低代码快速开发平台 Mate Code</p>
                <p>&nbsp;&nbsp;低代码平台是一个革命性的工具，使您能够更快、更简单地构建应用程序，而无需大量编写代码。无论您是企业用户还是个人开发人员，低代码平台都将为您提供许多独特的价值</p>
              </div>
              <div className='text_detail_right'>
                <img  src={head_cener_right} alt=''/>
              </div>
            </div>
          </div>
          <div className="introduce_code">
                <p>低代码Mate Code特点</p>
                <ul className="item_tab">
                    <li>
                      <span> <BulbFilled style={{fontSize: '42px'}} /></span>
                      <p>基于Spirng开发</p>
                      <p>项目基于Spring Boot和Spring Cloud开发，贴合大多数公司技术栈，方便二次开发</p>
                    </li>
                    <li>
                      <span> <PieChartFilled style={{fontSize: '42px'}} /></span>
                      <p>支持多功能</p>
                      <p>目前支持OAuth2双因子认证，Websocket，多租户，权限菜单基础路由，Flyway，自动代码生成</p>
                    </li>
                    <li>
                      <span> <BugFilled  style={{fontSize: '42px'}} /></span>
                      <p>多Module解偶设计</p>
                      <p>多模块的设计用于解决简单单点应用与分布式微服务依赖和部署问题，优化代码，使得二次开发更便捷，按需处理，便于调试和简化代码</p>
                    </li>
                    <li>
                      <span> <BuildFilled style={{fontSize: '42px'}} /></span>
                      <p>搭配第三方可选集成组件</p>
                      <p>对于常用到的第三方服务进行二次封装，轻松搞定三方服务，例如支付、第三方单点登录、云存储、短信等</p>
                    </li>
                </ul>
            </div>
            <Divider/>
            <div className='support_type'>
                <p>框架支持</p>
                <p>ERP系统功能集成</p>
                <ul>
                  <li>
                    <div>
                      <span> <LockFilled style={{fontSize: '42px'}} /></span>
                      <span>Oauth2权限认证服务，单点登录</span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span> <SettingFilled style={{fontSize: '42px'}} /></span>
                      <span>OA系统</span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span> <FundFilled style={{fontSize: '42px'}} /></span>
                      <span>统计报表</span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span> <CloudFilled style={{fontSize: '42px'}} /></span>
                      <span>微服务一体化</span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span> <RocketFilled style={{fontSize: '42px'}} /></span>
                      <span>Docker K8s集成部署</span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span> <ThunderboltFilled style={{fontSize: '42px'}} /></span>
                      <span>快速模板开发</span>
                    </div>
                  </li>
                </ul>
            </div>
            <Divider/>
            <div className='dependence_introduce'>
              <span>技术点</span>
              <div>
                <img src={require('../../images/bg01.png')} alt=""/>
                <span>后端技术栈 Spring boot 2.7、Spring Cloud 2021 、Spirng Cloud Alibaba 2021，Nacos作为服务发现。基础模块仅单纯依赖Mybatis，功能业务模块使用了Mybatis Plus。
                  使用Flyway来管理数据库脚本版本，保证数据库deploy脚本版本一致性。使用Spring Authorization Server来做OAuth2的认证授权。Xxl-job定时任务。
                </span>
              </div>
              <div>
                <span>前端React生态，Ant Design搭建起来的中后台应用，简约风格，交互友好，支持国际化。开发友好，修改简单，适合多种场景。</span>
                <img src={require('../../images/bg03.png')} alt=""/>
              </div>
              <div>
                <img src={require('../../images/bg04.png')} alt=""/>
                <span>技术团队对接友好，文档丰富，支持远程技术支持和一体化服务部署。支持商业业务外包，个性化定制。</span>
              </div>
            </div>
            <FooterView/>
        </div>
     )
}

export default Home