import HeadView from '../../ components/head'
import FooterView from '../../ components/footer'
import './index.css'
import {Divider} from 'antd';


const APP = () => {

    return (
        <div>
           <div className='top_part'>
             <HeadView/>
             <div className='top_bg'>
                <img src={require('../../images/bg08.jpg')} alt='' />
                <span>用新的方式 做点新的东西</span>
             </div>
           </div>
            <Divider/>
           <div className='part_title'>
                <span style={{color: "#D2D2D2"}}>Introduce</span>
           </div>
           <div className='introduce_01'>
                <img src={require('../../images/contact_01.jpg')} alt='' />
           </div>
     
           <div className='introduce_02'>
               <img className='img2' src={require('../../images/contact_02.jpg')} alt='' />
           </div>
           <FooterView/>
        </div>
    )
}


export default APP;