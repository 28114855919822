import React from "react"
import './index.css';



const APP = React.FC = ()=>{
    return (
        <footer>
            <div className="footer">
                <div className="footer_all">
                    <div className="contact_item">
                        <p>联系地址：苏州市姑苏区东环路657号2幢103室（创智赢家E幢103-3室）</p>
                        {/* <p>联系电话： </p> */}
                        <p>邮箱邮箱：904198187@qq.com</p>
                    </div>
                    <div className="contact_copyright">
                        <p>&copy; 2023 苏州随你吧网络科技有限公司. All rights reserved.</p>
                        <p>ICP备案: <a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2024080685号-1</a></p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default APP