import './index.css';
import {Row,Col} from 'antd';




const APP = () => {

    return (
        <div>
            <Row className='menu_context'>
            <Col span={6}>
                <img src={require('../../images/logo.jpg')} alt='' style={{ width: '150px',height: '35px'}}/>
            </Col>
            <Col span={18}>
                <div className='menu'>
                <a href='/home'><span>首页</span></a>
                <a href='/solution'><span>解决方案</span></a>
                {/* <a href='/about'><span>产品</span></a> */}
                <a href='/about'><span>关于我们</span></a>
                </div>
                </Col>
            </Row>
        </div>
    )

}


export  default APP;
